import React from 'react';

const HeroSection = () => (
    <div className="grid grid-cols-3 items-center container mx-auto px-4">
        <div className="menu-content hidden absolute right-0 w-40 mt-2 py-2 bg-white shadow-xl rounded-lg pr-4">
            <a href="#" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Home</a>
            <a href="#" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">About</a>
            <a href="#" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Services</a>
            <a href="#" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Contact</a>
        </div>
    </div>
);

export default HeroSection;
