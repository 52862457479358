import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import TutoringSection from './components/TutoringSection';
import Products from './components/Products';
import Footer from './components/Footer';
import TutorProfile from './TutorProfile';

const Home = () => (
  <>
    <section className='hero-section'>
        <HeroSection />
    </section>
    <section>
        <TutoringSection />
    </section>
    <section>
        <Products />
    </section>
  </>
);

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!menuOpen) return;
      const menuContent = document.querySelector('.menu-content');
      if (menuContent && !menuContent.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <Router>
      <main>
        <Header toggleMenu={toggleMenu} menuOpen={menuOpen} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tutor" element={<TutorProfile />} />
        </Routes>
        <Footer />
      </main>
    </Router>
  );
}

export default App;
