import React from 'react';
import { Link } from 'react-router-dom';

const TutoringSection = () => (
    <div className="bg-gray-100 text-center py-12" id="tutoring-section">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">Personalized Data Science and Programming Tutoring</h2>
        <p className="text-gray-600 mb-8">Boost your learning with our personalized tutoring sessions in Data Science, Java, Python, and more.</p>
        <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="border rounded-lg p-4 shadow-lg bg-white">
                    <h3 className="text-lg font-bold">BTEC Data Science Tutoring</h3>
                    <p>Expert tutoring for BTEC Level 1, 2, and 3 Data Science topics.</p>
                    <Link to="/tutor" className="mt-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Learn More
                    </Link>
                </div>
                <div className="border rounded-lg p-4 shadow-lg bg-white">
                    <h3 className="text-lg font-bold">Python Programming Classes</h3>
                    <p>Master Python programming with our personalized classes, suitable for all levels.</p>
                    <Link to="/tutor" className="mt-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Learn More
                    </Link>
                </div>
                <div className="border rounded-lg p-4 shadow-lg bg-white">
                    <h3 className="text-lg font-bold">Degree Level Java Tutoring</h3>
                    <p>Excel in your degree with our comprehensive Java tutoring sessions.</p>
                    <Link to="/tutor" className="mt-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Learn More
                    </Link>
                </div>
            </div>
        </div>
    </div>
);

export default TutoringSection;
