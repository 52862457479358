import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const menu = document.querySelector('.menu-content');
            if (menu && !menu.contains(event.target) && !event.target.classList.contains('menu')) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className="bg-blue-600 text-white py-4 md:py-12">
            <div className="grid grid-cols-3 items-center container mx-auto px-4">
                <div className="col-span-1 pl-2 md:pl-10">
                    <Link to="/">
                        <img src="inverted-teessideBytes.jpg" alt="Teesside Bytes Logo - Data Science and Programming Tutoring Company" className="h-12 w-12 md:h-24 md:w-24" />
                    </Link>
                </div>
                <div className="col-span-1 text-center">
                    <h1 className="text-xl md:text-3xl font-bold">Teesside Bytes</h1>
                    <p className="text-sm md:text-xl">Private Tutoring</p>
                </div>
                <div className="col-span-1 flex justify-end pr-2 md:pr-10">
                    <div className="relative space-y-2 cursor-pointer" onClick={toggleMenu}>
                        <span className={`block w-6 h-1 md:w-8 md:h-1 bg-white transition-transform ${menuOpen ? 'transform rotate-45 translate-y-2' : ''}`}></span>
                        <span className={`block w-6 h-1 md:w-8 md:h-1 bg-white transition-opacity ${menuOpen ? 'opacity-0' : ''}`}></span>
                        <span className={`block w-6 h-1 md:w-8 md:h-1 bg-white transition-transform ${menuOpen ? 'transform -rotate-45 -translate-y-2' : ''}`}></span>
                    </div>
                    <div className={`menu-content ${menuOpen ? 'block' : 'hidden'} absolute right-0 mt-8 md:mt-12 w-40 py-2 bg-white shadow-xl rounded-lg pr-4`}>
                        <Link to="/" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Home</Link>
                        <Link to="/tutor" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Tutor</Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
