import React from 'react';
import DuncanSkiltonImage from './DuncanSkilton.jpg'; 

const TutorProfile = () => (
    <div className="bg-white py-12">
        <div className="text-left pl-4">
            <h2 className="text-3xl font-bold text-gray-800 mb-2">Expert Data Science and Programming Tutor in Teesside</h2>
            <p className="text-gray-600 mb-4">Serving Middlesbrough, Stockton, Darlington, Hartlepool, and Redcar & Cleveland</p>
        </div>
        <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-2 items-start">
                <div className="col-span-3 flex justify-center md:justify-start">
                    <img src={DuncanSkiltonImage} alt="Duncan Skilton - Expert Computer Science and Programming Tutor" className="w-72 h-72 rounded-lg object-cover border border-gray-300 shadow-md" />
                </div>
                <div className="col-span-9 md:ml-2">
                    <p className="text-gray-800 mb-4">I am a passionate and experienced Software Developer tutor available for both face-to-face and online tutoring sessions across Teesside. With a strong background in advanced Data Science, Object-Oriented Design, and Programming, I am committed to helping students excel in their studies and develop a deep understanding of the subject.</p>
                    <p className="text-gray-800 mb-4 font-bold">Why Choose Me as Your Data Science and Programming Tutor?</p>
                    <ul className="list-disc list-inside text-gray-800">
                        <li className="mb-2">Extensive Teaching Experience: Levels 1, 2, 3, 4, and 5 in Computer Science, Software Development, and IT courses.</li>
                        <li className="mb-2">Advanced Knowledge: OOP, Spring Boot, SQL, Big-O Notation, and more.</li>
                        <li className="mb-2">Practical Industry Insights: VCS, Clean Code principles, Application Development, and Software Testing.</li>
                        <li className="mb-2">Continuous Learning: Regularly update my GitHub with new projects and knowledge, and I am working towards my Masters in Software Development.</li>
                        <li className="mb-2">Flexible Tutoring: Available for sessions in the evenings and weekends.</li>
                    </ul>
                </div>
            </div>
			<div className="text-left pl-4">
				<h2 className="text-3xl font-bold text-gray-800 mb-2">Contact</h2>
				<p className="text-gray-600 mb-4">To discuss tutoring times and costs, please feel free to reach out to me on LinkedIn, or by using info@teessidebytes.co.uk</p>
        	</div>
        </div>
    </div>
);

export default TutorProfile;
