import React from 'react';

const products = [
    { id: 1, title: 'Adobe Exams', description: 'Including Digital Video using Premiere Pro, Visual Design using Adobe Photoshop', price: 300 },
    { id: 2, title: 'CertiPort Exams', description: 'IC3 GS5 Levels 1, 2 and 3, IC3 GS6 Levels 1, 2 and 3', price: 150 },
    { id: 3, title: 'Cisco Exams', description: 'Cisco Certified Support Technician Cybersecurity and Networking', price: 300 }
];

const ProductListing = () => (
    <div className="container mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold text-gray-800 text-center pb-10">Coming Soon</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {products.map(product => (
                <div key={product.id} className="border rounded-lg p-4 shadow-lg text-center">
                    <h3 className="text-lg font-bold">{product.title}</h3>
                    <p>{product.description}</p>
                </div>
            ))}
        </div>
    </div>
);

export default ProductListing;
