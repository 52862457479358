import React from 'react';

const Footer = () => (
    <footer className="bg-blue-600 text-white text-center py-4">
        © 2024 Teesside Bytes. All rights reserved.
        <div className="flex justify-center space-x-4 mt-4">
            <a href="https://www.teessidebytes.co.uk" className="text-white hover:text-gray-300">Data Science Tutoring</a>
            <a href="https://github.com/DSkilton" className="text-white hover:text-gray-300">Programming Examples, Github</a>
            <a href="https://duncancodes.com" className="text-white hover:text-gray-300">Software Development Tutor</a>
            <a href="https://www.linkedin.com/in/duncan-skilton-4603903a/" className="text-white hover:text-gray-300">LinkedIn</a>
        </div>
    </footer>
);

export default Footer;
